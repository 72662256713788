/* If you need to add @import statements, do so up here */
@import "fonts";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 13px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body {
    background: theme("colors.black");
    font-weight: theme("fontWeight.bold");
    color: theme("colors.white");
  }
}

@layer components {
  .text-content h2 {
    font-size: theme("fontSize[3.5xl]");
    line-height: theme("lineHeight.tight");
    margin-bottom: theme("spacing.12");
  }

  @media screen(lg) {
    .text-content h2 {
      font-size: theme("fontSize.5xl");
    }
  }

  .text-content h3 {
    font-size: theme("fontSize[3.5xl]");
    line-height: theme("lineHeight.tight");
    margin-bottom: theme("spacing.12");
  }

  .text-content h4 {
    font-size: theme("fontSize.2xl");
    line-height: theme("lineHeight.tight");
    margin-bottom: theme("spacing.12");
  }

  .text-content p {
    margin-bottom: theme("spacing.12");
  }

  .text-content img {
    margin: theme("spacing.10") auto;
  }
}
